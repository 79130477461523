// Grid settings
$enable-flex:           true;
$main-sm-columns:       12;
$sidebar-sm-columns:    4;

// Colors
$negro:#000000;
$blanco:#ffffff;
$error:#ff0000;
$correcto:#66cc66;

$color-primary:#000000;
$color-secondary:#ffffff;
$bg-grey:#ebebeb;
$txt-grey:#9d9c9c;
$color-magenta:#E5007D;
$color-azul:#004CCF;

$verde-oscuro:#3a604b;
$verde-claro:#a2c037;
$naranja:#e1391b;
$morado:#7940a6;
$rosa:#eb078d;
$azul:#0f299e;
$gris:#b4b4b4;
$rosa-hover:#4d203c;
$azul-hover:#131c42;
$gris-hover:#3f4148;




$size-rem: 			16px;

$titulo-size-xxl:	60px;
$titulo-size-xl:	40px;
$titulo-size-l:		35px;
$titulo-size-m:		30px;
$titulo-size-s:		25px;
$titulo-size-xs:	20px;
$titulo-size-xxs:	18px;
$titulo-size-xxxs:	10px;

$body-size-xxl:		30px;
$body-size-xl:		25px;
$body-size-l:		20px;
$body-size-m:		18px;
$body-size-s:		16px;
$body-size-xs:		14px;