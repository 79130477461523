footer{
	padding:50px 0 0px;
	// position: absolute;
	bottom: 0px;
	width: 100%;
	background-color: transparent;
	.btn-secondary{
		font-family: 'Gotham Medium';
	}
	@include media-breakpoint-down(md) {
		padding:0px;
	}	
	@include media-breakpoint-down(sm) {
		padding-bottom: 20px;
		padding-top: 20px;
	}	
	@media (max-height: 700px) {
		padding:30px 0 0px;
	}
	/* height: 19vh; 
	max-height: 170px;*/
	margin-bottom: 0px;
	.cont-logos{
		padding-left: 0px;
		list-style: none;
		margin-top:40px;
		@include media-breakpoint-down(md) {
			padding-left: 0;
			list-style: none;
			margin-top: 20px;
			display:block;
		}
		li{
			display: inline-block;
			@include media-breakpoint-down(md) {
				margin: 10px 8px;
			}
			img{
				height:30px;
			}
		}
	}
	.menu{
		padding:0;
		text-align: center;
		position: relative;
		margin-top:40px;
		@media only screen and (max-width: 1270px) {
			margin-bottom: 45px;
		}
		@include media-breakpoint-down(md) {
			margin-bottom: 45px;
		}
		@media (max-height: 700px) {
			margin-top:30px;
		}
		li{
			list-style: none;
			display: inline-block;
			@include fontSize($body-size-xs);
			font-family: 'Gotham Book';
			a{
				text-transform: unset;
				color:$blanco;
				&:hover{
					color:$txt-grey;
					text-decoration: none;
				}
			}
			&:after{
				content:"|";
				margin:0 5px;
				color:$blanco;
			}
			&:last-child{
				// @include media-breakpoint-down(lg) {
				// 	display: block;
				// 	margin-top: 15px;
				// }
				&:after{
					content:none;
					margin:0;
				}
			}

			/*cambio boton contacta para mobile*/
			.btn-secondary.btn-xs{
				@media only screen and (max-width: 1270px) {
					position: relative;
					left: 0;
					top: 4px;
					padding: 6px 14px;
				}
				
			}
		}
	}
}

.home-area, .interior{
	footer{
		background: rgb(239,249,255);
		background: -moz-linear-gradient(90deg, rgba(239,249,255,1) 0%, rgba(221,241,255,1) 100%);
		background: -webkit-linear-gradient(90deg, rgba(239,249,255,1) 0%, rgba(221,241,255,1) 100%);
		background: linear-gradient(90deg, rgba(239,249,255,1) 0%, rgba(221,241,255,1) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eff9ff",endColorstr="#ddf1ff",GradientType=1);
		// height:140px;
		padding:0px;
		margin-top:30px;
		.container{

		}
		.cont-logos{
			display:none!important;
		}
		.logo-tlc{
			display:block!important;
		}
		.btn-secondary{
			display:none!important;
		}
		.menu{
			li{
				a{
					color:$color-azul;
				}
				&:after{
					color:$color-azul;
				}
			}
		}
	}
}

body.reseteo-fin{
	height:100vh;
	footer{
		position:absolute;
	}
}

.modal{
	.modal-content{
		.modal-body{
				.form-group{
					position:relative;

					input{
						width: 100%;
						height: 38px;
						border:none;
						background-color: $bg-grey;
						border:solid 1px $bg-grey;
						text-align: left;
					}

					textarea{
						width: 100%;
						height: 38px;
						border:none;
						background-color: $bg-grey;
						border:solid 1px $bg-grey;
						text-align: left;
						height: 215px;
					}
					

					&.has-error{

						input{
							border:solid 1px red;
						}

						textarea{
							border:solid 1px red !important;
						}
					}

				}

				.cajaCheck input[type="checkbox"]:checked,
				.cajaCheck input[type="checkbox"]:not(:checked) {
				  position: absolute;
				  left: -9999px;
				  opacity:0;
				}

				.cajaCheck input[type="checkbox"]:checked + label,
				.cajaCheck input[type="checkbox"]:not(:checked) + label
				{
				  position: relative;
				  padding-left: 28px;
				  cursor: pointer;
				  line-height: 20px;
				  display: inline-block;
				  color: $negro;
				}
				.cajaCheck input[type="checkbox"]:checked + label:before,
				.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
				  content: '';
				  position: absolute;
				  left: 0;
				  top: 0;
				  width: 18px;
				  height: 18px;
				  border: 2px solid $negro;
				}
				.cajaCheck input[type="checkbox"]:checked + label:after,
				.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
				  content: '';
				  width: 18px;
				  height: 18px;
				  background: $negro;
				  position: absolute;
				  top: 0px;
				  left: 0px;
				  -webkit-transition: all 0.2s ease;
				  transition: all 0.2s ease;
				}
				.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
				  opacity: 0;
				  -webkit-transform: scale(0);
				  transform: scale(0);
				}
				.cajaCheck input[type="checkbox"]:checked + label:after {
				  opacity: 1;
				  -webkit-transform: scale(1);
				  transform: scale(1);
				}

				a{
					text-decoration: underline;
					color:$negro;
				}

		}

	}

}


#modal-cookies{
	display: none;
	bottom: 0;
	left: 0;
	width: 100%;
	padding: 20px;
	z-index: 100;
	background-color: $blanco;
	position: fixed;
	-webkit-box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	-moz-box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	box-shadow: 0px -2px 20px 0px rgba(0,0,0,0.3);
	@include media-breakpoint-down(sm) {
		padding: 0 0 20px;
	}
	a{
		text-decoration: underline;
	}
	.btn-secondary.btn-xs{
		@include media-breakpoint-down(sm) {
			position: relative;
			left: 0;
		}
	}
	.btn-link{
		border:2px solid $color-magenta;
		color:$color-magenta;
		border-radius:4px;
		text-decoration:none;
		line-height:42px;
		width:100%;
		margin-right:15px;
		@include fontSize(12px);
		font-family: 'Gotham Medium';
		height:45px;
	}
	.btn-primary{
		background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
		color:$blanco;
		min-width: 150px!important;
		width:auto!important;
		display: inline-block!important;
		border-radius:4px;
		@include fontSize(12px);
		font-family: 'Gotham Medium';
		height:45px;
	}
	.texto-cookies{
		@include fontSize(12px);
		line-height:1.2em;
	}
	.cont-cheks{
		display: block;
		width: 100%;
		bottom: -100px;
		margin-top:10px;
		// padding-left: 15px;
		// padding-right: 15px;
		.border{
			padding: 10px 0px 0px;
		}
		.cajaCheck{
			margin-bottom:0px;
			input{
				height: 25px;
				margin-bottom: 10px;
			}
		}
	}
}

#modal-registro{

	.modal-dialog{
		max-width: 1100px;
	}

	.modal-content{
		width: 1100px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}
		.modal-header{
			border-bottom: none;
			
			.modal-title{
				// background-color: $negro;

				span{
					// background-color: $blanco;
				}
			}
			.modal-title{
				@include fontSize(20px!important);
				font-family:'Gotham Bold';
				color:$negro;
			}
			.modal-subtitulo{
				@include fontSize(20px!important);
				font-family:'Gotham Bold';
				color:$negro;
			}
		}

		.modal-body{

			#alerta-errores-registro{
				display: none;
			}
			label{
				color:$negro;
				&:not(.checkbox){
					padding-left: 12px;
				}
			}
			.cuadro-txt-legal{
				color:$negro;
			}
			h3{
				color:$negro;
			}
		}

	}

}


#modal-validado{

	.modal-dialog{
		max-width: 745px;
	}

	.modal-content{
		width: 745px;
		max-width: 90vw;

		.modal-header{
			border-bottom: none;
		
			.modal-title{
				font-family:'Gotham Bold'!important;
				color:$color-magenta;
				span{
					background-color: $blanco;
				}
			}

		}

	}

}

#modal-descargabono{
	display:none;
	background-color: rgba(255, 255, 255, 0.7);
	.modal-dialog{
		border-radius: 8px;
		-webkit-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
		-moz-box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
		box-shadow: 0px 0px 25px 0px rgba(0,0,0,0.1);
	}

	.modal-content{
		// width: 1100px;
		// max-width: 90vw;
		border:none;
		margin-left: auto;
		margin-right: auto;
		padding-bottom:15px;
		.modal-header{
			border-bottom: none;
			z-index: 5;
			padding: 25px 15px 15px 0px;
			.modal-title{
				@include fontSize(20px!important);
				font-family:'Gotham Bold';
				color:$negro;
				padding-bottom: 10px;
				border-bottom: 1px solid #C4C4C4;
			}
			.modal-subtitulo{
				@include fontSize(20px!important);
				font-family:'Gotham Medium';
				color:$negro;
				margin-top:20px;
			}
			.close{
				opacity:1;
				span{
					position: absolute;
				    top: 15px;
				    right: 15px;
				    img{
				    	height: 15px;
						width: 15px;
						@include media-breakpoint-down(xs) {
							height: 15px;
							width: 15px;
						}
				    }
				}
			}		
		}
		.cont-logo{
			img{
				height: 85px;
				margin-bottom: 15px;
				margin-top: 15px;
			}
		}
		.modal-body{
			padding: 0px 15px;
			.cont-formulario{
				margin-top:0px;
				.form-group{
					margin-top:0px;
					label{
						font-family: 'Gotham Book';
						@include fontSize(12px!important);
						padding-left:25px;
						a{
							@include fontSize(12px!important);
							font-family: 'Gotham';
						}
					}
					input{
						border-radius:4px;
						border:1px solid #C4C4C4;
						background-color:$blanco;
					}
					::-webkit-input-placeholder { /* Edge */
						color: #C4C4C4;
					}
					
					:-ms-input-placeholder { /* Internet Explorer 10-11 */
						color: #C4C4C4;
					}
					
					::placeholder {
						color: #C4C4C4;
					}
				}
				.btn-secondary{
					margin-bottom:15px;
					width:100%;
					background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
					border-radius:100px;
					color:$blanco;
					width:215px;
					margin-left:auto;
					margin-right:auto;
					display: block;
					@include fontSize(16px);
					font-family: 'Gotham Medium';
					line-height: 2.28em;
					margin-top:40px;
					&.small{
						line-height: 1.7em;
						height: 40px;
						margin-top:0px;
					}
				}
			}
			.texto-body{
				color:$negro;
				@include fontSize(16px!important);
				margin-bottom:15px;
			}
			#alerta-errores-descargabono{
				display: none;
			}

		}

	}

}

#modal-bonook{

	.modal-dialog{
		max-width: 745px;
	}

	.modal-content{
		width: 745px;
		max-width: 90vw;
		@include media-breakpoint-down(md) {
			max-width: calc(100vw - 15px);
		}

		.modal-header{
			border-bottom: none;
		
			.modal-title{

				span{
					background-color: $blanco;
				}
			}

		}

	}

}

.modal{
	background-color: rgba(255, 255, 255, 0.6)!important;
	.modal-title{
		font-family:'Gotham Bold'!important;
		color:$color-magenta;
		border-bottom: 1px solid #C4C4C4;
		width:100%;
	}
	.modal-header{
		border-bottom:none;
	}
	.modal-content{
		border:none!important;
		padding-bottom: 30px;
	}
	.modal-body{
		color:$negro;
		@include fontSize(16px!important);
		font-family: 'Gotham Book';
		text-align:center;
		p{
			color:$negro;
			@include fontSize(16px!important);
			font-family: 'Gotham Book';
		}
	}
	.btn-secondary, a{
		margin-bottom:15px;
		width:100%;
		background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
		border-radius:100px;
		color:$blanco;
		width:215px;
		margin-left:auto;
		margin-right:auto;
		display: block;
		@include fontSize(16px);
		font-family: 'Gotham Medium';
		line-height: 2.28em;
		margin-top:40px;
		text-decoration:none;
		// p{
		// 	color:$blanco;
		// }
		&.small{
			line-height: 1.7em;
			height: 40px;
			margin-top:0px;
		}
		@media (max-height: 700px) {
			margin-top:20px;
			height: 35px;
		}
	}
	.modal-button{
		a{
			margin-top:20px;
		}
	}
}


.btcontacta{
	text-decoration: none!important;
	color: #fff!important;
}
.hrefcontacta{
	background: none!important;
	border-radius: none!important;
	color: #000;
	width: 215px;
	margin: none!important;
	display: inline;
	font-size: 16px;
	font-family: Gotham Medium;
	line-height: 2.28em;
}