html{
	font-size:$size-rem;
	@include media-breakpoint-down(xs) {
		font-size: 14px;
	}
	@media (max-height: 900px) {
		font-size: 13px;
	}
	body{
		@extend .fuente-body;
		@include fontSize($body-size-m);
		color: $txt-grey;
		line-height: 1.2em;
		@include media-breakpoint-down(md) {
			overflow-x: hidden;
		}
		.container{
			width: 100%!important;
			max-width: 1220px;
			@media (max-height: 900px) {
				max-width: 1024px;
			}
			&.container-medium{
				max-width: 1380px;
			}
		}
		&.hidescroll{
			overflow-y: hidden;
			height: 100vh;
		}
		#wrapper{
			@include media-breakpoint-down(sm) {
			overflow-x:hidden;
			padding-left: 15px;
			padding-right: 15px;
			}
		}
	}
}
.cont-user{
	.border-bottom-azul{
		border-bottom:2px solid #DEF2FF;
	}
	.nombre{
		&.border-bottom-azul{
			@include media-breakpoint-down(xs) {
				border:none;
			}
		}
	}
}
body{
	&.home{
		background-image: url('../images/inicio-bg.jpg');
		background-size: cover;
		background-position: top center;
		height:calc(100vh - 277px);
		position:relative;
		background-repeat: no-repeat;
		@include media-breakpoint-down(xs) {
			height:unset;
		}
	}
}
section{
	position:relative;
	.btn-back{
		position: absolute;
		top: 0px;
		left: 50px;
		@include media-breakpoint-down(md) {
			position: absolute;
			top: -30px;
			left: 0px;
			z-index: 10;
		}
		img{
			@include media-breakpoint-down(md) {
				width:35px;
			}
			@include media-breakpoint-down(sm) {
				width:30px;
			}
		}
	}
}
#section-home{
	
	/*height: calc(100vh - 450px);*/
	position:relative;
	// min-height: unset!important;
	height:100%;
	min-height: calc(100vh - 271px)!important;
	@media (max-height: 1000px) {
		/*height: calc(55vh);*/
		min-height: auto;
	}
	@include media-breakpoint-down(md) {
		padding-top: 50px;
		min-height: auto;
	}
	.btn-primary{
		background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
		border-radius:100px;
		color:$blanco;
		min-width: 150px;
		width:auto!important;
		display: inline-block!important;
		margin-left:auto;
		margin-right:auto;
		margin-top:40px;
		@include fontSize(16px);
		font-family: 'Gotham Medium';
		@media (max-height: 700px) {
			margin-top:20px;
			height: 35px;
		}
	}

	.cont-ventana{
		background-color: rgba(0,0,0,0.3);
		border-radius:8px;
		padding:25px;
		@include media-breakpoint-down(xs) {
			padding: 15px 10px 20px;
		}
		@media (max-height: 700px) {
			padding:15px;
		}
		.campo{
			@include border-radius(4px!important);
		}
		&.cont-recordar, &.cont-reset, &.cont-reset-fin, &.cont-bienvenido, &.cont-activacion, &.cont-validar, &.cont-validado{
			h1{
				@include fontSize(20px);
				font-family: 'Gotham Bold';
				border-bottom:1px solid $blanco;
				padding-bottom:10px;
			}
			.subtitulo{
				margin-top:15px;
				margin-bottom:30px;
				line-height: 1.2em;
			}
			label{
				font-family: 'Gotham Medium';
			}
			.mini{
				@include fontSize(12px);
				line-height:1.2em;
				color:$blanco;
			}
			input{
				@include border-radius(4px!important);
			}
			.has-error{
				input{
					&.form-control{
						// color:#D82240;
					}
				}
			}
			a.btn-primary{
				line-height:2.4em;
				@media (max-height: 900px) {
					line-height:3em;
				}
				@media (max-height: 700px) {
					line-height:2.1em;
				}
			}
		}
		&.cont-validar{

			label{
				@include fontSize(12px);
				font-family: 'Gotham Light';
				width:100%;
				line-height:1.2em;
				margin-top:20px;
			}
			.h1{
				@include fontSize(20px);
				font-family: 'Gotham Bold';
				border-bottom:1px solid $blanco;
				padding-bottom:10px;
			}
			input{
				margin-top:40px;
			}
			p{
				color:$blanco;
				margin-top:15px;
			}
		}
		&.cont-reset{
			p{
				margin-top: 15px;
				margin-bottom: 20px;
			}
			.ico-mostrar{
				position: absolute;
				right: 30px !important;
				top: 40px !important;
			}
		}
		&.cont-reset-fin{
			h1{
				@include fontSize(32px);
				color:$color-magenta;
			}
			p{
				margin-top:30px;
			}
			a{
				line-height:2.25em;
			}
		}
		&.cont-bienvenido{
			p{
				margin-top:15px;
			}
			a{
				line-height:2.25em;
			}
		}
		&.cont-activacion{
			p{
				margin-top:15px;
			}
			a{
				line-height:2.25em;
			}
		}
		::-webkit-input-placeholder { /* Edge */
			color: #495057;
		}
		
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #495057;
		}
		
		::placeholder {
			color: #495057;
		}
		.nav-tabs{
			border-bottom:none;
			.nav-item{
				width:50%;
				border-bottom:1px solid $blanco;
				.nav-link{
					border-radius: 0px;
					border:none;
					background-color: transparent;
					color: $blanco;
					@include fontSize(20px);
					&.active{
						border-bottom: 4px solid white;
					}
				}
			}
		}
		.tab-1{
			padding-top:40px;
			@include media-breakpoint-down(xs) {
				padding: 25px 15px 15px 15px;
			}
			@media (max-height: 700px) {
				padding-top:20px;
			}
			label{
				font-family: 'Gotham Medium';
			}
			input.form-control{
				@include border-radius(4px);
			}
			.ico-mostrar{
				position: absolute;
				right: 15px!important;
				top: 15px!important;
			}
			.cont-links{
				margin-top:40px;
				@include fontSize(12px);
				padding-bottom: 3px;
				border-bottom: 1px solid white;
				color:$blanco;
				@include media-breakpoint-down(xs) {
					display:block!important;
				}
				@media (max-height: 700px) {
					margin-top:20px;
				}
				a{
					color:$blanco;
					text-decoration:underline;
				}
			}
			select{
				font-family: 'Gotham Book';
				width: 100% !important;
				option{
					font-family: 'Gotham Book'!important;
				}
			}
			.cont-checkbox{
				label{
					@include fontSize(12px);
					line-height:1.4em;
					color:$blanco;
					a{
						color:$blanco;
						text-decoration:underline;
					}
				}
				.css-checkbox{
					width: 15px !important;
					height: 15px !important;
					margin-right:10px;
				}
			}
		}
	}
	.tab-2{
		margin-top:40px;
		@include media-breakpoint-down(sm) {
			margin-top:0px;
		}
		@include media-breakpoint-down(xs) {
			padding: 25px 0px 15px 0px;
		}
		label{
			font-family: 'Gotham Medium';
		}
		.ico-mostrar{
			right: 25px;
			top: 40px;
			position:absolute;
		}
		.titulo-legal{
			@include fontSize(10px);
			color:$blanco;
			line-height: 12px;
		}
		.cuadro-txt-legal{
			h3{
				margin-top:20px;
				margin-bottom:15px;
				@include fontSize(10px);
				line-height:1.4em;
			}
			p, a{
				@include fontSize(10px);
				line-height:1.4em;
				color:$blanco;
			}
			a{
				text-decoration:underline;
			}
			.mas-info{
				color:$blanco;
			}
		}
		select{
			font-family: 'Gotham Book';
			width: 100% !important;
			option{
				font-family: 'Gotham Book'!important;
			}
		}
		.cont-checkbox{
			label{
				@include fontSize(10px);
				line-height:1.4em;
				color:$blanco;
				font-family: 'Gotham Light';
				a{
					color:$blanco;
					text-decoration:underline;
					font-family: 'Gotham Light';
				}
			}
			.css-checkbox{
				width:unset !important;
				height:unset !important;
				margin-right:10px;
			}
		}
	}
}
#wrapper{
	.cont-user{
		padding-bottom: 15px;
		margin-top:40px;
		@include media-breakpoint-down(sm) {
			padding-top:20px;
			margin-top:0px;
		}
		.btn-primary{
			margin-top:0px!important;
		}
		.btn-primary{
			margin-bottom:15px;
			background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
			border-radius:100px;
			color:$blanco;
			min-width: 150px;
			width:auto!important;
			display: inline-block!important;
			margin-left:auto;
			margin-right:auto;
			@include fontSize(16px);
			font-family: 'Gotham Medium';
			line-height: 2.28em;
			margin-top:40px;
			text-decoration:none;
			@include media-breakpoint-down(sm) {
				@include fontSize(15px);
			}
			@include media-breakpoint-down(xs) {
				@include fontSize(16px!important);
				width:100%;
				margin-top:10px!important;
			}
			@media (max-height: 700px) {
				margin-top:20px;
				height: 35px;
			}
			&.small{
				line-height: 1.7em;
				height: 40px;
				margin-top:0px;
				@media (max-height: 1000px) {
					line-height:2em!important;
				}
				@media (max-height: 900px) {
					line-height:2.4em!important;
				}
				@include media-breakpoint-down(sm) {
					line-height:2em!important;
				}
				@media (max-height: 700px) {
					line-height:2.3em;
				}
			}
		}
		.border-bottom{
			border-bottom:2px solid #DEF2FF;
		}
		.total_creditos{
			font-family: "Gotham Book";
			@include fontSize(24px);
			line-height: 1.2em;
			color:$negro;
			margin-top:0px;
			@include media-breakpoint-down(sm) {
				@include fontSize(15px);
			}
			@include media-breakpoint-down(xs) {
				@include fontSize(20px);
				padding-bottom:10px;
				padding-left: 15px;
				padding-right: 15px;
			}
			span{
				font-family: "Gotham Bold";
			}
		}
	}
}

.home-area{
	background-color:$blanco;
	background-image: none!important;
	
	#wrapper{
		padding-left:15px;
		padding-right:15px;
	}
	.section-area{
		margin-top:40px;
		position:relative;
		min-height: calc(100vh - 293px);
		@include media-breakpoint-down(sm) {
			margin-top:10px;
		}
		.cont-user{
			padding-bottom: 15px;
			.btn-primary{
				margin-top:0px!important;
			}
		}
		.border-bottom{
			border-bottom:2px solid #DEF2FF;
		}
		.total_creditos{
			font-family: "Gotham Book";
			@include fontSize(24px);
			line-height: 1.2em;
			color:$negro;
			span{
				font-family: "Gotham Bold";
			}
		}
		#formCodigo{
			@include media-breakpoint-down(sm) {
				margin-top:-40px;
			}
		}
		form{
			input.form-control{
				@include border-radius(4px);
			}
			.ico-mostrar{
				position: absolute;
				right: 30px!important;
				top: 15px!important;
			}
			.cont-checkbox{
				label{
					@include fontSize(12px);
					line-height:1.4em;
					color:$blanco;
					a{
						color:$blanco;
						text-decoration:underline;
					}
				}
				.css-checkbox{
					width: 15px !important;
					height: 15px !important;
					margin-right:10px;
				}
			}
			input{
				border:1px solid #C4C4C4;
			}
		}
		.btn-primary{
			margin-bottom:15px;
			background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
			border-radius:100px;
			color:$blanco;
			min-width: 150px;
			width:auto!important;
			display: inline-block!important;
			margin-left:auto;
			margin-right:auto;
			@include fontSize(16px);
			font-family: 'Gotham Medium';
			line-height: 2.28em;
			margin-top:40px;
			&.small{
				line-height: 1.7em;
				height: 40px;
				margin-top:0px;
			}
			@media (max-height: 700px) {
				margin-top:20px;
				/*height: 35px;*/
			}
		}
		h2{
			@include fontSize(20px);
			font-family: 'Gotham Bold';
			color:$negro;
			padding-bottom:10px;
			border-bottom:1px solid #C4C4C4;
			margin-top:40px;
			margin-bottom: 40px
		}
		.cont-historial{
			border-radius:4px;
			border:1px solid #C4C4C4;
			margin-bottom:10px;
			padding:10px;
			&.activo, &.inactivo{
				.codigo, .creditos{
					display: block;
					@include fontSize(12px);
					font-family: 'Gotham Medium';
					p{
						background-image: linear-gradient(60deg, #649FFF, #004ACF);
						-webkit-background-clip: text;
						color: transparent;  
						@include fontSize(12px);
						font-family: 'Gotham Medium';
					}
				}
				.creditos{
					border:2px solid;
					border-image-slice: 1;
					background-clip: padding-box, border-box;
					background-origin: padding-box, border-box;
					background-image: linear-gradient(#fff, #fff), linear-gradient(#649FFF, #004ACF);
					border-radius:100px;
					max-width: 120px;
					height: 30px;
					line-height: 2.2em;
					margin-left: auto;
					margin-right: auto;
					pointer-events:none;
					p{
						line-height:2.4em;
						@include media-breakpoint-down(md) {
							line-height:2.2em;
						}
						@include media-breakpoint-down(xs) {
							line-height:2.8em;
						}
					}
					@include media-breakpoint-down(xs) {
						margin-top:10px;
						margin-bottom:10px;
					}
				}
				.fecha{
					@include fontSize(12px);
					font-family: 'Gotham Book';
					color:#868686;
				}
			}
			&.inactivo{
				p{
					background-image:none;
					-webkit-background-clip: unset;
					color: #868686;  
				}
				.creditos{
					border:2px solid #EBEBEB;
					border-image-slice: unset;
					background-clip: unset;
					background-origin: unset;
					background-image: none;
					border-radius:100px;
					max-width: 120px;
					height: 30px;
					line-height: 2.2em;
					margin-left: auto;
					margin-right: auto;
					background-color:#EBEBEB;
					color:$blanco;
					p{
						background-image:none;
						-webkit-background-clip: unset;
						color: $blanco;  
					}
				}
			}
		}
		&.add-credit, &.mod-pass{
			@include media-breakpoint-down(md) {
				padding-top:0px!important;
			}
			.total_creditos{
				font-family: "Gotham Book";
				@include fontSize(24px);
				line-height: 1.2em;
				color:$negro;
				margin-top:0px;
				@include media-breakpoint-down(sm) {
					@include fontSize(15px);
				}
				@include media-breakpoint-down(xs) {
					@include fontSize(20px);
					padding-bottom:10px;
					padding-left: 15px;
					padding-right: 15px;
				}
				span{
					font-family: "Gotham Bold";
				}
			}
			p{
				font-family: 'Gotham Book';
				@include fontSize(12px);
				color:$negro;
				line-height:1.4em;
				margin-top:20px;
			}
			.form-control::placeholder {
				color: #C4C4C4;
				opacity: 1;
			}
			.ico-mostrar{
				position: absolute;
				right: 30px!important;
				top: 40px!important;
			}
			label{
				font-family: "Gotham Book";
				@include fontSize(16px);
				line-height: 1.2em;
				color:$negro;
				padding-left:10px;
			}
			.btn-primary{
				@include media-breakpoint-down(sm) {
					margin-top:10px!important;
					margin-bottom:10px!important;
				}
			}
		}
		.btn-back{
			position: absolute;
			top: 0px;
			left: 50px;
			@include media-breakpoint-down(md) {
				position: absolute;
				top: -30px;
				left: 0px;
				z-index: 10;
			}
			@include media-breakpoint-down(sm) {
				position: absolute;
				top: 13px;
				left: 0px;
			}
			img{
				@include media-breakpoint-down(md) {
					width:35px;
				}
				@include media-breakpoint-down(sm) {
					width:30px;
				}
			}
		}
		.cont-mensajes{
			margin-top:50px;
			.cont-reset-fin{
				background-color:transparent!important;
			}
			h1{
				border-bottom:1px solid #C4C4C4!important;
			}
			p{
				color:$negro;
			}
		}
	}
}

.carrusel-partners{
	background-color: $bg-grey;
	h2{
		color:$rosa;
		@extend .fuente-title;
		@include fontSize(22px);
		font-weight: bold;
	}

	.contenidoSlide{
		height: 16vw;
		@include media-breakpoint-down(md) {
			height: 56vw;
		}
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		.bgimage{
			background-size: cover;
			filter: grayscale(100%);
			@include transition(all 0.4s ease-out);
			width: 100%;
			height: 100%;
			position: absolute;
			z-index: 1;
			left: 0;
			top: 0;
		}
		.titulo{
			@extend .fuente-title;
			@include fontSize(18px);
			color:$color-primary;
			background-color: rgba($rosa,0.8);
			height: 80px;
			width: 100%;
			color:$blanco;
			display: block;
			border-left: 1px solid rgba($blanco,0.5);
			position: relative;
			z-index: 2;
			@include transition(all 0.4s ease-out);
			padding:0 15px;
		}
		&:hover{
			.bgimage{
				filter: grayscale(0%);
			}
			.titulo{
				height:100px;
				text-decoration: none;
			}
		}

	}
	.swiper-button-next{
		top: 40%;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%23ffffff'/%3E%3C/svg%3E");
	}
	.swiper-button-prev{
		top: 40%;
		background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%23ffffff'/%3E%3C/svg%3E");
	}
}
.section-logos-redes{
	.iconos-redes{
		padding:0;
		margin:12px 0 0; 
		@media (max-height: 1000px) {
			/*margin:20px 0 0;*/
		}
		li{
			margin:0 8px;
			display: inline;
			@include media-breakpoint-down(md) {
				margin:2px 8px;
			}
			img{
				height: 60px!important;
				@media (max-height: 1000px) {
					/*height: 50px;*/
				}
				@include media-breakpoint-down(md) {
					height: 50px!important;
				}
			}
		}
	}
}
.filtro{
	position:relative;
	.titulofiltro{
		cursor:pointer;
		padding-bottom: 10px;
	}

	.bocadillo{
		position:absolute;
		top:30px;
		left: 44%;
		width: 700px;
		max-width: 100vw;
		transform: translateX(-50%);
		background-color: $blanco;
		border:solid 1px $negro;
		z-index: 3;
		transition: all 0.5s;
		opacity:0;
		border-radius:10px;
		@include media-breakpoint-down(xl) {
			left: 0px;
			transform: unset;
			top:35px;
		}
		@include media-breakpoint-down(md) {
			width:100%;
		}
		// @include media-breakpoint-down(md) {
		// 	left: -8px;
		// 	transform: translateX(0);
		// 	width: calc(100vw - 15px);
		// 	overflow-y: scroll;
		// 	max-height: calc(100vh - 270px);
		// 	overflow-x: hidden;
		// }
		.row-checks{
			@include media-breakpoint-down(md) {
				padding-left: 7px!important;
				padding-right: 7px!important;
			}
			
		}
		.flecha{
			display: block;
			position:absolute;
			top: -6px;
			left: 50%;
			transform: rotate(45deg);
			background-color: $blanco;
			border-top: solid 1px $negro;
			border-left: solid 1px $negro;
			width: 10px;
			height: 10px;
			@include media-breakpoint-down(xl) {
				left: 50%;
			}
		}
		&.bocadillo-inout{
			width: 200px;
		}
		&.bocadillo-provincias{
			@include media-breakpoint-down(md) {
				/*transform: translateX(calc(-50% - 15px));
				.flecha{
					left: 75%!important;
				}*/
			}
		}
		&.bocadillo-categorias	{
			@include media-breakpoint-down(md) {
				/*transform: translateX(calc(-32% - 15px));
				.flecha{
					left: 55%!important;
				}*/
			}
			/*.cajaCheck{
				label{
					width:50px;
				}
			}JC*/
		}
		.cajaCheck input[type="checkbox"]:checked,
		.cajaCheck input[type="checkbox"]:not(:checked) {
		  position: absolute;
		  left: -9999px;
		  opacity:0;
		//   text-transform: capitalize; //JC 17mayo
		}

		.cajaCheck input[type="checkbox"]:checked + label,
		.cajaCheck input[type="checkbox"]:not(:checked) + label
		{
		  position: relative;
		  padding-left: 28px;
		  cursor: pointer;
		  line-height: 20px;
		  display: inline-block;
		  color: $negro;
		  font-size: 16px;
		//   text-transform: capitalize; //JC 17mayo
		@include media-breakpoint-down(md) {
			font-size: 14px;
			  
		  }
		  @include media-breakpoint-down(xs) {
			font-size: 13px;
			font-family: 'Gotham Light';
			line-height: 16px;
			padding-left: 23px;
			  
		  }
		}
		.cajaCheck input[type="checkbox"]:checked + label:before,
		.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
		  content: '';
		  position: absolute;
		  left: 0;
		  top: 0;
		  width: 18px;
		  height: 18px;
		  //border: 2px solid $negro;
		  border-radius: 100%;
		  background-color: grey;
		}
		.cajaCheck input[type="checkbox"]:checked + label:after,
		.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
		  content: '\f00c';
		  font-family: FontAwesome;
		  width: 18px;
		  height: 18px;
		  position: absolute;
		  top: 0px;
		  left: 0px;
		  border-radius: 100%;
		  -webkit-transition: all 0.2s ease;
		  transition: all 0.2s ease;
		  color:white;
		  font-size: 11px;
		  line-height: 18px;
		  text-align: center;
		}
		.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
		  // opacity: 0;
		  // -webkit-transform: scale(0);
		  // transform: scale(0);
		  color:white;
			background: rgba(206,206,206,1);
			background: -moz-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: -webkit-gradient(left top, right bottom, color-stop(0%, rgba(206,206,206,1)), color-stop(33%, rgba(206,206,206,1)), color-stop(100%, rgba(255,255,255,1)));
			background: -webkit-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: -o-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: -ms-linear-gradient(-45deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			background: linear-gradient(135deg, rgba(206,206,206,1) 0%, rgba(206,206,206,1) 33%, rgba(255,255,255,1) 100%);
			filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#cecece', endColorstr='#ffffff', GradientType=1 );		  
		}
		.cajaCheck input[type="checkbox"]:checked + label:after {
		  // opacity: 1;
		  // -webkit-transform: scale(1);
		  // transform: scale(1);
		  background-color: #E5007D;
		  color:white;
		}


	}

	@include media-breakpoint-up(md) {

		&:hover{
			.bocadillo{
				visibility: visible !important;
				opacity:1;
				display: block !important;
				transform: translateX(-50%);
				left: 50%;
			}
		}	

	}


	@include media-breakpoint-down(sm) {

		&.activo{

			.bocadillo{
				visibility: visible !important;
				opacity:1 !important;
				display: block !important;
			}
		}	

	}

}

#section-planes{
	@include fontSize($body-size-m);
	// min-height: calc(100vh - 260px);
	min-height: calc(100vh - 293px);
	.container{
		max-width:1140px!important;
	}
	h1{
		@extend .title-l;
		color:$negro;
		line-height: 1em;
		@include media-breakpoint-down(md) {
			@include fontSize(26px);
		}
	}
	margin-top: 40px;
	@include media-breakpoint-down(sm) {
		margin-top: 0px;
	}
	.total_creditos{
		font-family: "Gotham Book";
		@include fontSize(24px);
		line-height: 1.2em;
		color:$negro;
		span{
			font-family: "Gotham Bold";
		}
	}
	.btn-primary{
		margin-bottom:15px;
		background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
		border-radius:100px;
		color:$blanco;
		margin-left:auto;
		margin-right:auto;
		min-width: 150px;
		width:auto!important;
		display: inline-block!important;
		@include fontSize(16px);
		font-family: 'Gotham Medium';
		line-height: 2.28em;
		margin-top:40px;
		&.small{
			line-height: 1.7em;
			height: 40px;
			margin-top:0px;
		}
		@media (max-height: 700px) {
			margin-top:20px;
			height: 35px;
		}
	}
	.border-bottom{
		border-bottom: 2px solid #def2ff !important;
	}
	.cont-titular{
		margin-top:50px;
		@include media-breakpoint-down(sm) {
			margin-top: 0px;
		}
		
		h2{
			font-family: "Gotham Book";
			@include fontSize(18px);
			line-height: 1.2em;
			color:$txt-grey;
			margin-top:20px;
		}
	}
	.tab-filtro{
		@include fontSize(28px);
		font-family: 'Gotham Light';
		height: 80px;
		overflow: hidden;
		cursor: pointer;
		@include media-breakpoint-down(md) {
			@include fontSize(20px);
		}
		@include media-breakpoint-down(xs) {
			@include fontSize(18px);
		}
		a{
			color:$blanco;
			white-space: nowrap;
			z-index: 10;
			padding-bottom: 4px;
			position: relative;
			@include media-breakpoint-down(md) {
				white-space: normal;
				transform: scaleX(0.9);
				letter-spacing: -1px;
			}
			&:hover{
				text-decoration: none;
			}
			&:before{
				content:" ";
				position: absolute;
				left: 0;
				bottom: 0;
				width: 0%;
				border-bottom:2px solid $blanco;
				@include transition(all 0.7s ease-out);

			}
		}
		strong{
			font-family: 'Gotham Bold';
		}
		&.tab-inhome{
			background-color: $rosa;
			/*@include transform(scaleX(104%));*/
			@include transform(scaleX(1.04));
			@include media-breakpoint-down(md) {
				@include transform(scaleX(1.3));
			}
			&:after{
				content:" ";
				position: absolute;
				background-color: rgba($rosa-hover,0.5);
				width: 0%;
				top: 0;
				height: 100%;
				left: -30px;
				@include transition(all 0.5s ease-out);
				transform: skew(-10deg);
			}
			&:hover{
				&:after{
					width: 15%;
				}
			}
		}
		&.tab-all{
			display: contents;
			a{
				background-color: $gris;
				padding: 0 20px;
				border-left:3px solid $blanco;
				border-right:3px solid $blanco;
				transform: skewX(-10deg);
				z-index: 10;
				overflow-x: hidden;

				span{
					transform: skewX(10deg);
					z-index: 10;
					padding:3px 0;
					margin:auto;
					&:before{
						content:" ";
						position: absolute;
						left: 0;
						bottom: 0;
						width: 0%;
						border-bottom:2px solid $blanco;
						@include transition(all 0.7s ease-out);
		
					}
		
				}
				&:after{
					content:" ";
					position: absolute;
					background-color: rgba($gris-hover,0.5);
					width: 0%;
					top: 0;
					height: 100%;
					left: 00px;
					@include transition(all 0.5s ease-out);
				}
				&:hover{
					&:after{
						width: 25%;
					}
				}
			}
		}
		&.tab-outhome{
			background-color: $azul;
			/*@include transform(scaleX(104%));*/
			@include transform(scaleX(1.04));
			@include media-breakpoint-down(md) {
				@include transform(scaleX(1.3));
			}
			&:before{
				content:" ";
				position: absolute;
				background-color: rgba($azul-hover,0.5);
				width: 0%;
				top: 0;
				height: 100%;
				left: -30px;
				@include transition(all 0.5s ease-out);
				transform: skew(-10deg);
			}
			&:hover{
				&:before{
					width: 15%;
				}
			}
		}
		&.selected{
			&.tab-inhome{
				&:after{
					background-color: $rosa-hover;
					width: 110%;
				}
				a{
					&:before{
						width: 100%;
					}
				}
			}
			&.tab-all{
				a{
					&:after{
						background-color: $gris-hover;
						width: 125%;
					}
					span{
						&:before{
							width: 100%;
						}
					}
				}
			}
			&.tab-outhome{
				&:before{
					background-color: $azul-hover;
					width: 110%;
				}
				a{
					&:before{
						width: 100%;
					}
				}
			}
		}

		@media all and (-ms-high-contrast:none)
		{

			&.tab-all a.js_tipo-red { height: 100%; } /* IE10 */
			*::-ms-backdrop, &.tab-all a.js_tipo-red  {  height: 100% } /* IE11 */
			&.tab-inhome,&.tab-outhome{
				@include transform(scaleX(1.14));
			}
			*::-ms-backdrop, &.tab-inhome,&.tab-outhome{
				@include transform(scaleX(1.14));
			}
		}

	}
	.cont-planes{
		margin-top:60px;
		@include media-breakpoint-down(sm) {
			margin-top: 30px;
		}
	}

	.item{
		cursor: pointer;
		// height: 375px;
		height:300px;
		width: 215px;
		position: relative;
		margin-bottom: 15px;
		@include transition(all 0.4s ease);
		transition-delay: 0.2s;
		margin-bottom: 20px;
		@include media-breakpoint-down(md) {
			height:220px;
		}
		// @include media-breakpoint-down(md) {
		// 	height: 63vw;
		// 	width: 48%;
		// }
		.cont-info{
			height:100%;
		}
		.imagen{
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			height: 100%;
			border-radius: 10px;
			@include transition(all 0.5s ease);
			@include media-breakpoint-down(md) {
				// height: calc(63vw - 80px);
				// border-top-left-radius: 10px;
				// border-top-right-radius: 10px;
				// border-bottom-left-radius: 0;
				// border-bottom-right-radius: 0;
				height: 100% !important;
				border-radius: 12px;
			}
		}
		.capa{
			position: absolute;
			left:.25rem;
			bottom: 0;
			width: calc(100% - 0.5rem);
			height: 80px;
			@include transition(height 0.5s ease);
			border-bottom-left-radius:10px;
			border-bottom-right-radius:10px;
			border-top-left-radius:0px;
			border-top-right-radius:0px;
			.icono-check{
				overflow: hidden;
				height: 0px;
				@include transition(height 0.4s ease);
				#svg-check{
					width: 35px;
					height: 35px;
					circle{
						stroke-dasharray: 185;
						stroke-dashoffset: 185;
						@include transition(all 0.4s ease-out);
					}
					polyline{
						stroke-dasharray: 70;
						stroke-dashoffset: 70;
						@include transition(all 0.4s ease-out);
					}
				}
			}
			img{
				position: relative;
				top: 10px;
				@include media-breakpoint-down(xs) {
					height: 47px!important;
					position: absolute;
					top: 20px
				}
				
			}
		}
		&:hover{
			@include media-breakpoint-up(md) {

				// filter: brightness(70%);
				.capa{
					height: 100%;
					border-top-left-radius:10px;
					border-top-right-radius:10px;
					@include transition(all 0.4s ease);
					img{
						position: absolute;
						top: 20px
					}
				}
				.texto-hover{
					display:block;
					opacity:1;
				}
			}
		}
		&.js-seleccionada{
			// filter: brightness(33%);
			&:hover{
				// filter: brightness(33%);
			}
			.capa{
				height: 100%;
				position:absolute;
				top:0px;
				border-radius: 10px;
				img{
					position: absolute;
					top: 20px
				}
				.icono-check{
					height:35px;
					bottom: 20px;
					position: relative;
					#svg-check{
						circle{
							transition-delay: 0.3s;
							stroke-dashoffset: 0;
						}
						polyline{
							transition-delay: 0.5s;
							stroke-dashoffset: 0;
						}
					}
				}
			}
			.imagen{
				filter: brightness(53%);
			}
			.texto-hover{
				display:block;
				opacity:1;
			}
		}
		&.js-redinactiva{
			transform: scale(0);
			width: 0;
			padding: 0!important;
		}
		.texto-hover{
			position: absolute;
			top: 80px;
			z-index:2;
			color:white;
			opacity:0;
			padding-left:15px;
			padding-right:15px;
			@include transition(all 1s ease);
			@include fontSize(14px);
			line-height:1.3em;
		}
	}
	.grid-items{
		.item{
			position:relative;
			cursor: pointer;
			transition: all 0.4s ease-out;
			@media (max-height: 900px) and (orientation: landscape)  {
				height: 270px;
				overflow:hidden;
			}
			.fondo-dsk{
				@media (max-height: 900px) and (orientation: landscape)  {
				    position: relative;
				    margin-top: -20px;
				}
			}
			h2{
				color:$negro;
				text-transform: uppercase;
			}

			.capa{
				transition: background-color 0.5s ease;
				background-color: transparentize($negro, 0.5);
				position:absolute;
				top: 0px;
				left: 0px;
				border-bottom-left-radius:10px;
				border-bottom-right-radius:10px;
				@include transition(all 1s ease);
				.check{
					position: absolute;
					bottom: 10%;
					left: 50%;
					margin-left: -25px;
					border: 3px solid
					white;
					border-radius: 50%;
					width: 50px;
					height: 50px;
					text-align: center;
					line-height: 43px;
					opacity: 0;
					transition:all 0.5s ease;
					@include media-breakpoint-down(md) {
						width: 40px;
						height: 40px;
						margin-left: -20px;
						line-height: 33px;
						img{
							width: 20px;
							height: 20px;
						}
					}
					@include media-breakpoint-down(xs) {
						width: 32px;
						height: 32px;
						margin-left: -16px;
						line-height: 26px;
						img{
							width: 16px;
							height: 16px;
						}
					}
				}
			}

		}

		.item-bienestar{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($verde-oscuro, 0);
					}

					}
				}
				.categoria-activa{
					background-color: transparentize($verde-oscuro, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-deporte{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($verde-claro, 0);
					}

					}

				}
				.categoria-activa{
						background-color: transparentize($verde-claro, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-planes{
			max-width: 244px;
			.item{
				.capa{
					
					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($naranja, 0);
					}

					}

				}
				.categoria-activa{
						background-color: transparentize($naranja, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-kids{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

					&:hover{
						background-color: transparentize($rosa, 0);
					}

					}

				}
				.categoria-activa{
						background-color: transparentize($rosa, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}

		.item-talento{
			max-width: 244px;
			.item{
				.capa{

					@include media-breakpoint-up(md) {

						&:hover{
							background-color: transparentize($morado, 0);
						}

					}

				}
				.categoria-activa{
						background-color: transparentize($morado, 0);
					.check{
						opacity: 1;
					}
				}
			}
		}								

	}
}

#section-plan-aviso{
	@include fontSize($body-size-m);

	.aviso-consumo{
		@include media-breakpoint-down(md) {
			padding: 0 15px;
		}
	}
	.info-usuario{
		padding-left: 10vw;
		padding-right: 10vw;
		border:2px solid $negro;
		margin-top:45px;
		margin-bottom:45px;
		@include media-breakpoint-down(md) {
			margin-top:0;
			margin-bottom:30px;
		}
		@media (max-height: 900px) {
			padding-bottom:15px!important;
			padding-top:15px!important;

		}
		p{
			font-family: 'Gotham Light';
			color:$negro;
			strong{
				@include fontSize(22px);
				font-family: 'Gotham Book';
			}
		}
	}
}

#section-plan{
	@include fontSize($body-size-m);
	min-height: calc(100vh - 738px);
	@include media-breakpoint-down(sm) {
		position: absolute;
		width: 100%;
		left: 0px;
		top: 0px;
		min-height: unset;
	}
	.cont-titular{
		margin-top: 50px;
		@include media-breakpoint-down(sm) {
			margin-top:10px;
		}
		h1{
			@extend .title-l;
			color:$negro;
			line-height: 1em;
			@include media-breakpoint-down(md) {
				@include fontSize(26px);
			}
		}
		h2{
			font-family: "Gotham Book";
			@include fontSize(18px);
			line-height: 1.2em;
			color:$txt-grey;
			margin-top:20px;
		}
	}
	.titular{
		h1{
			color:$negro!important;
			line-height: 1.5em;
			font-size: 1em;
		}		
	}
	.seleccion{
		// margin-top: 10px;
		// left: 0;
		// padding-top: 0px;
		background-color: white;
		z-index: 10;
		position: fixed;
		top: 65px;
		margin-top: 15px!important;
		left: auto;
		right: auto;
		padding-top: 5px;
		margin-left: -15px;
		width: 100%;
		max-width: 1220px;
		&.con-aviso{
			top:235px;
		}

		@include media-breakpoint-down(md) {
			top: 45px;
			left: 15px;
			padding-top: 10px;
		}
		@include media-breakpoint-down(sm) {
			margin-top: 25px;
			position:absolute!important;
		}
		@media (max-height: 900px) {
			max-width: 1024px;
		}
		
	

		ul{
			// padding: 0;
			margin:0;
			padding-top: 80px;
			padding-bottom: 40px;
			padding-left:0px;
			@include media-breakpoint-down(md) {
				margin-bottom: 15px;
			}
			@include media-breakpoint-down(sm) {
				padding-top: 10px;
				padding-bottom: 0px;
				margin-bottom:0px;
			}
			li{
				padding: 0px;
				margin:0;
				list-style: none;
				display: inline-block;
				@include media-breakpoint-down(md) {
					padding: 5px 0px;
					display: block;
				}
				.titulofiltro{
					@include fontSize(18px);
					padding-left: 22px;
				}
				.badge{
					background-color:$negro;
					@include border-radius(50%);
					width: 22px;
					height: 22px;
					color: $blanco;
					font-size: 14px;
					line-height: 24px;
					font-family: "Gotham Bold";
					@include transform(scale(0%));
					@include transition (all 0.5s ease);
					transform-origin: 50% 50%;
					text-align: center;
					padding:0;
					letter-spacing: -1px;
					vertical-align: middle;
					&.activo{
						@include transform(scale(100%));
					}			
				}
				.filtro{
					border-radius: 50px;
					border: 1px solid #979797;
					padding: 10px;
					display: block !important;
					margin-right:15px;
					span{
						padding:0px;
						color:$negro;
						display:flex;
						&:after{
							content:"";
							background-image: url("../images/ico-arrow-down-gris.svg");
							width:14px;
							height:8px;
							display:block;
							margin-top: 8px;
							margin-left: 10px;
							@include media-breakpoint-down(md) {
								position: absolute;
								right: 15px;
								margin-top: 5px;
							}
						}
					}
				}
			}
		}
	}

	.grid-items{
		// margin-top: 40px;
		margin-top: 253px;
		transition: all 0.5s ease-out;

		@include media-breakpoint-down(md) {
			margin-top: 370px!important;
		}
		// @include media-breakpoint-down(sm) {
		// 	margin-top: 480px!important;
		// }
		// @media screen and (max-height: 900px) {
		// 	margin-top: 370px !important;
		// }
		@include media-breakpoint-down(sm) {
			margin-top: 350px !important;
		}

		.new-item{
			cursor: pointer;
			// height: 375px;
			height:300px;
			width: 215px;
			position: relative;
			margin-bottom: 15px;
			@include transition(all 0.4s ease);
			transition-delay: 0.2s;
			margin-bottom: 50px;
			@include media-breakpoint-down(md) {
				height: 220px!important;
				width: 48%;
				top:10px;
				// margin-top:10px!important;
			}
			.cont-info{
				height:100%;
			}
			.con-info{
				@include media-breakpoint-down(md) {
					height: 220px!important;
				}
			}
			.imagen{
				background-repeat: no-repeat;
				background-size: cover;
				background-position: center;
				// height: 375px;
				height:300px;
				border-radius: 10px;
				@include transition(all 0.5s ease);
				@include media-breakpoint-down(md) {
					height: 220px;
				}
			}
			.capa{
				position: absolute;
				left:.25rem;
				bottom: 0;
				width: calc(100% - 0.5rem);
				height: 80px;
				@include transition(height 0.5s ease);
				border-bottom-left-radius:10px;
				border-bottom-right-radius:10px;
				border-top-left-radius:0px;
				border-top-right-radius:0px;
				@include media-breakpoint-down(md) {
					// bottom: -30px;
				}
				.icono-check{
					overflow: hidden;
					height: 0px;
					@include transition(height 0.4s ease);
					#svg-check{
						width: 35px;
						height: 35px;
						circle{
							stroke-dasharray: 185;
							stroke-dashoffset: 185;
							@include transition(all 0.4s ease-out);
						}
						polyline{
							stroke-dasharray: 70;
							stroke-dashoffset: 70;
							@include transition(all 0.4s ease-out);
						}
					}
				}
				img{
					position: relative;
					top: 10px;
					@include media-breakpoint-down(xs) {
						height: 47px!important;
						position: absolute;
						top: 20px
					}
					
				}
			}
			&:hover{
				// filter: brightness(70%);
				.capa{
					height: 100%;
					border-radius:10px;
					border-top-left-radius:10px;
					border-top-right-radius:10px;
					@include transition(all 0.4s ease);
					img{
						position: absolute;
						top: 20px
					}
				}
				.texto-hover{
					display:block;
					opacity:1;
				}
			}
			&.js-seleccionada{
				// filter: brightness(33%);
				&:hover{
					// filter: brightness(33%);
				}
				.capa{
					height: 100%;
					position:absolute;
					top:0px;
					border-radius: 10px;
					img{
						position: absolute;
						top: 20px
					}
					.icono-check{
						height:35px;
						bottom: 20px;
						position: relative;
						#svg-check{
							circle{
								transition-delay: 0.3s;
								stroke-dashoffset: 0;
							}
							polyline{
								transition-delay: 0.5s;
								stroke-dashoffset: 0;
							}
						}
					}
				}
				.imagen{
					filter: brightness(53%);
				}
				.texto-hover{
					display:block;
					opacity:1;
				}
			}
			&.js-redinactiva{
				transform: scale(0);
				width: 0;
				padding: 0!important;
			}
			.texto-hover{
				position: absolute;
				top: 80px;
				z-index:2;
				color:white;
				opacity:0;
				padding-left:15px;
				padding-right:15px;
				@include transition(all 1s ease);
				@include fontSize(14px);
				line-height:1.3em;
			}
		}
		.item{
			position:relative;
			// height: 286px;
			color:$negro;
			@include media-breakpoint-down(md) {
				height: auto;
			}
			@include media-breakpoint-down(sm) {
				margin-bottom: 20px !important;
			}

			.imagen{
				// object-fit: cover;
				@include transform(translateX(-50%));
				left: 50%;
				height: 100!important;
				position:relative;
				@include media-breakpoint-down(md) {
					height: 220px;
				}
			}
			.titulo-plan{
				position: relative;
				padding-left: 20px;
				&:before{
					content:" ";
					background-image: url(../images/stripes.svg);
					background-repeat: no-repeat;
					width: 30px;
					height: 26px;
					position: absolute;
					top: 0;
					left: 0;
					background-size: contain;
					@include media-breakpoint-down(md) {
						top:-3px;
					}
				}
			}
			.titulo-plan,.texto-plan{
				color:$negro;
				line-height: 1em;
				p{
					line-height: 1.2em;
				}
			}

			h2{
				color:$negro;
				text-transform: uppercase;
			}
		}		
		.label-in{
			color: #e83788;
			padding:3px 20px;
			border: 2px solid #e83788;
			font-family: Gotham Bold;
			font-size: 0.7em;
			font-weight: bolder;
			img{
				margin-left: 6px;
			}
		}	
		.label-out{			
			color: #0f299e;
			padding:3px 20px;
			border: 2px solid #0f299e;
			font-family: Gotham Bold;
			font-size: 0.7em;
			font-weight: bolder;
			img{
				margin-left: 6px;
			}
		}					

		&.only-in{
			.label-in{
				display: inline;
			}
			.label-out{
				display: none;
			}
		}
		&.only-out{
			.label-in{
				display: none;
			}
			.label-out{
				display: inline;
			}
		}
	}
	#alert-filtro{
		padding-right: 30px;
		padding-left: 30px;
	}
	.cont-user{
		@media screen and (max-height: 800px) {
			margin-top:0px;
			padding-bottom:0px;
		}
	}
	.cont-titular{
		@media screen and (max-height: 800px) {
			margin-top:15px;
		}
	}
	.cont-filtro{
		@media screen and (max-height: 800px) {
			padding-top:15px!important;
			padding-bottom:15px!important;
		}
	}
}
body{
	&.scroll{
		#section-plan{
			.seleccion{
				position: fixed;
				top: 65px;
				margin-top: 15px!important;
				left: auto;
				right: auto;
				padding-top: 5px;
				margin-left: -15px;
				width: 100%;
				max-width: 1220px;
				box-shadow: 0px 14px 14px -14px rgba(0,0,0,.3);
				@include media-breakpoint-down(lg) {
					max-width: 1024px;
				}
				
				@include media-breakpoint-down(sm) {
					top: 45px;
					position:absolute!important;
				}
				@media (max-height: 900px) {
					max-width: 1024px;
				}
				// @media (max-height: 900px) {
				// 	max-width: 1024px;
				// }
				// @media screen and (max-height: 700px) {
				// 	position:absolute;
				// 	top: -280px;
				// }
			}
			.grid-items{
				@include media-breakpoint-down(sm) {
					margin-top: 350px !important;
				}
			}
		}		
	}
}
#section-centros{
	@include fontSize($body-size-m);
	min-height: calc(100vh - 260px);
	.cont-user{
		@include media-breakpoint-down(sm) {
			margin-top:0px;
		}
	}
	.label-in{
		color: #e83788;
		padding:3px 15px;
		border: 2px solid #e83788;
		font-family: Gotham Bold;
		font-size: 0.7em;
		font-weight: bolder;
		img{
			margin-left: 6px;
		}
	}	
	.label-out{			
		color: #0f299e;
		padding:3px 15px;
		border: 2px solid #0f299e;
		font-family: Gotham Bold;
		font-size: 0.7em;
		font-weight: bolder;
		img{
			margin-left: 6px;
		}
	}
	.distance{
		@include fontSize(16px);
		color: #eb078d;
		font-family: Gotham Bold;
		margin-bottom: 0;
		margin-top: 8px;
		position: relative;
		margin-left: 15px;
		transform: translateY(2px);
		&:before{
			content: " ";
			background-image: url(../images/stripe-black.svg);
			background-repeat: no-repeat;
			background-size: contain;
			width: 20px;
			height: 21px;
			position: absolute;
			top: -2px;
			left: -10px;
			
		}
	}
	.fa-arrow-left{
		color: #000;
		@include fontSize(30px);
	}
	.aviso-consumo{
		@include media-breakpoint-down(md) {
			padding: 0 15px;
		}
	}
	.info-usuario{
		padding-left: 10vw;
		padding-right: 10vw;
		border:2px solid $negro;
		margin-top:45px;
		margin-bottom:45px;
		@include media-breakpoint-down(md) {
			margin-top:0;
			margin-bottom:30px;
		}
		@media (max-height: 900px) {
			padding-bottom:15px!important;
			padding-top:15px!important;

		}
		p{
			font-family: 'Gotham Light';
			color:$negro;
			strong{
				@include fontSize(22px);
				font-family: 'Gotham Book';
			}
		}
	}
	.encabezado{
		height: 350px;
		background-color: $negro;
		background-size: cover;
		background-position: center center;
		@include media-breakpoint-down(md) {
			height: 300px;
		}
		@include media-breakpoint-down(sm) {
			height: 200px;
			margin-top:70px;
		}
		@media (max-height: 700px) {
			height: 275px;
		}

		.contendor-titulo{
			background-color: rgba($blanco,.40);
			padding: 25px 0 20px;
			.titulo{
				@include fontSize($titulo-size-xxl);
				color:$negro;
				line-height: 1em;
				@extend .fuente-title;
				padding-left: 30px;
				@include media-breakpoint-down(md) {
					@include fontSize(30px);
					padding-left: 22px;
				}
				&:before{
					display:none;
					content: " ";
					background-image: url(../images/stripes.svg);
					background-repeat: no-repeat;
					width: 60px;
					height: 44px;
					position: absolute;
					top: 5px;
					left: 15px;
					background-size: contain;
					@include media-breakpoint-down(md) {
						top:-2px;
						height: 26px;
					}
				}
			}
		}
		&.fixed{
			position: fixed;
			top: -275px;
			width: 100%;
			z-index: 9;
			.titulo{
				padding-left: 20px;
				@include fontSize(30px);

				&:before{
					height: 23px;
					top: 0px;
				}
			}
		}

	}
	.volver{
		&.fixed{
			position: fixed;
			z-index: 10;
			top: 192px;
			@include media-breakpoint-down(md) {
				top:85px;
			}
			@include media-breakpoint-down(sm) {
				top:80px;
				width: 100%;
				background: white;
				padding-top: 10px;
				padding-bottom: 10px;
				left:15px;
			}
			a{
				img{
					@include media-breakpoint-down(sm) {
						width:30px;
					}
				}
			}
		}
	}
	#row-listado{
		margin-top:-100px;
		#menu_filtros{
			padding-top:20px;
		}
		#mapa{
			top: 0;
			&.fixed{
				@include media-breakpoint-down(md) {
					top:490px;
				}
			}
			.sticky-top{
				top: 250px;
			}
		}
	}
	.nav-tabs{
		border-bottom: none;
		display: block;
		background-color: $blanco;
		margin-top:0px;
		border-radius: 25px;
		-webkit-box-shadow: 0px -15px 5px 2px rgba(0,0,0,0.2);
		box-shadow: 0px -15px 5px 2px rgba(0,0,0,0.2);
		// @include media-breakpoint-down(xl) {
		// 	margin-top: -23px;
		// }
		// @include media-breakpoint-down(md) {
		// 	margin-top: 0px;
		// }
		// @include media-breakpoint-down(sm) {
		// 	margin-top: 0px;
		// }
		a{
			// margin-left: 15px;
		}
		#txttotal_busqueda{
			padding-left: 15px;
		}
		&.fixed{
			position: fixed;
			top: 80px;
			width: 100%;
			z-index: 9;
			max-width: 1220px;
			left: auto;
			right: auto;
			transform: translateX(10px);
			padding: 24px 24px 18px 0;
			-webkit-box-shadow: 0 8px 14px -11px rgba(0,0,0,.3);
			-moz-box-shadow: 0 8px 14px -11px rgba(0,0,0,.3);
			box-shadow: 0 8px 14px -11px rgba(0,0,0,.3);

			@include media-breakpoint-down(md) {
				max-width: 100%;
				width: 100%;
				margin-left: 0;
				top: 90px;
				left: -10px;
				padding: 24px 15px 18px 0;
			}
			@media (max-height: 900px) {
				max-width: 1024px;
			}
			@media (max-height: 700px) {
				position:relative!important;
			}
		}
		.btn-terciary{
			border-radius: 50px;
			margin-right: 15px;
			line-height: 0.2em;
			border-radius: 50px;
			padding: 23px 35px;
			display: block !important;
			background: linear-gradient(262.04deg,#e5007d -1.64%,#ff7cc4 158.46%);
			background-color: rgba(0, 0, 0, 0);
			border: none;
			color: white;
			margin-right: 15px !important;
			margin-left: unset !important;
		}
		.chosen-container{
			// min-width:225px!important;
			// padding-right: 0 .25rem;
			border-radius: 50px;
			border: 1px solid #979797;
			padding: 10px;
			display: block !important;
			margin-right: 15px;
			min-width: 215px!important;
			width:225px!important;
			@media (max-height: 900px) {
				min-width:190px!important;
			}
			.chosen-single{
				border-radius:0px!important;
				border:none;
				// height:26px!important;
				background-color: transparent;
				box-shadow: none;
				// margin-left:-5px!important;
				text-transform: capitalize;
				span{
					margin-right:unset;
					position:relative;
					text-align: left;
					font-family: Gotham Book;
					@include fontSize(16px);
					margin-top: 1px;
					padding-right:40px;
					text-transform: capitalize;
					&:after{
						content: "";
						background-image: url(../images/ico-arrow-down-gris.svg);
						width: 14px;
						height: 8px;
						display: block;
						position: absolute;
						top: 8px;
						right: 0px;
					}
				}
			}
			.chosen-drop{
				border-radius: 4%;
				box-shadow: none;
				background-color: #fff;
				border: 1px solid #ccc !important;
				.active-result.highlighted{
					background-color:#3f4148;
					background-image:none;
				}
			}
			.chosen-results li.active-result{
				text-transform: capitalize;
				text-align: left;
			}
		}
		.chosen-container-active.chosen-with-drop .chosen-single{
			border: none;
			background-image:unset;
		}
		#menu_filtros{
			select{
				@include media-breakpoint-down(sm) {
					float:left;
					width:48%!important;
					margin: 0 1%;
					-webkit-appearance: none;
					border: none;
					padding: 10px;
					border-radius: 50px;
					border: 1px solid #979797;
					background-color: white;
					color: black;
				}
				&::after{
					@include media-breakpoint-down(sm) {
						content: " ";
						background-image: url(../images/ico-arrow-down-gris.svg);
						width: 14px;
						height: 8px;
						display: block;
						position: absolute;
						top: 8px;
						right: 0;
					}
				}
			}
			.btn-terciary{
				margin-left: .25rem !important;
				@include media-breakpoint-down(sm) {
					// margin-left: 0 !important;
					width: 48%;
					float: left;
					margin-right: 0px !important;
					margin:10px 1% 0;
				}
			}
		}
		
	}
	#txttotal_busqueda{
		margin-top:15px;
		display:block;
	}
	.borde{
		position: absolute;
		top: 0;
		left: 0;
		width: 30px;
		background-color: black;
		height: 100%;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	.subcategoria{
		position: absolute;
		top: 0;
		right: 55px;
		writing-mode: tb-rl;
		font-size: 10rem;
		font-family: Gotham Bold;
		text-transform: uppercase;
		white-space: nowrap;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	#listado{
		color:$negro;
		padding-left:25px;
		padding-right:25px;
		.item{
			// min-height:400px;
			min-height:auto;
			padding-top:30px;
			-webkit-box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
			-moz-box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
			box-shadow: 5px 0px 10px 0px rgba(0,0,0,0.1);
			border-bottom-left-radius:10px;
			border-bottom-right-radius:10px;
			h2{
				color:$negro;
				font-family: 'Gotham Medium';
				@include fontSize(20px);
				// &:before{
				// 	content:" ";
				// 	background-image: url(../images/stripes.svg);
				// 	background-repeat: no-repeat;
				// 	background-size: contain;
				// 	width: 20px;
				// 	height: 21px;
				// 	position: absolute;
				// 	top: 2px;
				// 	left: 15px;
				// }
				@include media-breakpoint-down(md) {
					@include fontSize(25px);
				}

			}
			h3{
				color:$negro;
			}
			.cuerpo{
				line-height: 1.2em;
			}
			.btn-skew{
				margin-top: 0px;
				margin-bottom: 10px;
			}
			&.active{
				background-color:#F2F2F2;
			}
			.cont-titulo-creditos{
				padding-left:15px;
				padding-right:15px;
				.cont-titulo, .cont-creditos{
					padding-bottom:10px;
					&.border-bottom-azul{
						border-bottom: 2px solid #004CCF;
					}
				}
			}
			.cont-descripcion{
				margin-top:15px;
				padding-bottom:20px;
				.actividad{
					font-family: 'Gotham Book';
					@include fontSize(16px);
					color:$negro;
				}
				.descripcion{
					font-family: 'Gotham Book';
					@include fontSize(12px);
					color:$negro;
				}
			}
			.cont-direccion{
				background-color:#F8F8F8;
				padding:10px;
				min-height:170px;
				img{
					max-height: 80px;
					height: auto;
				}
				h3{
					@include fontSize(12px);
					font-family: 'Gotham Medium';
				}
				.direccion{
					@include fontSize(12px);
					font-family: 'Gotham Book';
					line-height: 1.5em;
				}
			}
			.col-logo{
				a{
					border: 2px solid;
					border-image-slice: 100%;
					border-image-slice: 1;
					background-clip: padding-box,border-box;
					background-origin: padding-box,border-box;
					/*background-image: linear-gradient(#fff,#fff),linear-gradient(#649fff,#004acf);*/
					background-color: #004ccf;
					color: #fff;
					border-radius: 100px;
					max-width: 120px;
					height: 30px;
					line-height: 1.7em;
					margin-left: auto;
					margin-right: auto;
					display:block;
					padding-left: 15px;
					padding-right: 15px;
					pointer-events:none;
					p{
						/*background-image: linear-gradient(60deg,#649fff,#004acf);
						-webkit-background-clip: text;
						color: transparent;*/
						@include fontSize(12px);
						font-family: 'Gotham Medium';
						line-height: 2.4em;
						@media (max-height: 700px) {
							line-height: 2.9em;
						}
					}
				}
			}
			.cont-btn{
				background-color:$color-magenta;
				border-bottom-left-radius:10px;
				border-bottom-right-radius:10px;
				.btn-seleccionar{	
					transform: skewX(0);
					background-color:transparent;
					margin-bottom:0px;
					@include fontSize(16px);
					font-family: 'Gotham Medium';
					padding-right:5px;
					span{
						transform: skewX(0);
						&:after{
							content:"";
							background-size: cover;
							background-image: url('../images/ico-flecha-seleccionar.svg');
							width:13px;
							height:12px;
							display: block;
							position: relative;
							margin-left:10px;
						}
					}
				}
				&.no-credito{
					background-color:#d0d0d0;
					padding-top: 7px;
					padding-bottom: 7px;
					p{
						color:$blanco;
						@include fontSize(16px);
						font-family: 'Gotham Medium';
					}
				}
			}
		}								
		&.fixed{
			margin-top:580px; /*440*/
			@include media-breakpoint-down(sm) {
				margin-top:480px;
			}
		}
		@media all and (-ms-high-contrast:none) {
			.item { display: block !important;} /* IE10 */
			*::-ms-backdrop, .item {display: block !important; } /* IE10 */
		}
	}

	#map{
		height: 80vh;
	}
}

#section-contacta{
	@include fontSize($body-size-m);

	.texto{
		
		line-height: 1.2em;

		ul{
			margin:0px;
			padding:0px;
			padding-left: 35px;

			li{
				list-style: none;

				&:before {
				  content: "-";
				  text-indent: -10px;
				  position:absolute;
				}
			}
		}
	}

	.form-group{
		position:relative;
		// input{
		// 	width: 100%;
		// 	height: 48px;
		// 	border:none;
		// 	background-color: $bg-grey;
		// 	border:solid 1px $bg-grey;
		// 	text-align: left;
		// 	@include media-breakpoint-down(md) {
		// 		height: 45px;
		// 	}
		// }

		// textarea{
		// 	width: 100%;
		// 	border:none;
		// 	background-color: $bg-grey;
		// 	border:solid 1px $bg-grey;
		// 	text-align: left;
		// 	height: 215px;
		// 	padding: 15px;
		// }
		
		&.has-error{

			input{
				border:solid 1px red;
			}

			textarea{
				border:solid 1px red !important;
			}
		}
		label{
			font-family:'Gotham Medium';
			@include fontSize(16px);
			padding-left:10px;
		}
		input, textarea{
			border-radius:4px;
			border:1px solid #C4C4C4;
			background-color:$blanco;
			text-align:left;
			width:100%;
		}
		textarea{
			resize: none;
		}
		::-webkit-input-placeholder { /* Edge */
			color: #C4C4C4;
		}
		
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
			color: #C4C4C4;
		}
		
		::placeholder {
			color: #C4C4C4;
		}
	}

	a{
		@extend .fuente-title;
		color:$negro;
		text-decoration: underline;
	}

	.cajaCheck input[type="checkbox"]:checked,
	.cajaCheck input[type="checkbox"]:not(:checked) {
	  position: absolute;
	  left: -9999px;
	  opacity:0;
	}

	.cajaCheck input[type="checkbox"]:checked + label,
	.cajaCheck input[type="checkbox"]:not(:checked) + label
	{
	  position: relative;
	  padding-left: 28px;
	  cursor: pointer;
	  line-height: 20px;
	  display: inline-block;
	  color: $negro;
	}
	.cajaCheck input[type="checkbox"]:checked + label:before,
	.cajaCheck input[type="checkbox"]:not(:checked) + label:before {
	  content: '';
	  position: absolute;
	  left: 0;
	  top: 0;
	  width: 16px;
	  height: 16px;
	  border: 1px solid #C4C4C4;
	}
	.cajaCheck input[type="checkbox"]:checked + label:after,
	.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
	  content: '';
	  width: 18px;
	  height: 18px;
	  background: $negro;
	  position: absolute;
	  top: 0px;
	  left: 0px;
	  -webkit-transition: all 0.2s ease;
	  transition: all 0.2s ease;
	}
	.cajaCheck input[type="checkbox"]:not(:checked) + label:after {
	  opacity: 0;
	  -webkit-transform: scale(0);
	  transform: scale(0);
	}
	.cajaCheck input[type="checkbox"]:checked + label:after {
	  opacity: 1;
	  -webkit-transform: scale(1);
	  transform: scale(1);
	}

	@include media-breakpoint-up(sm) {
		.chosen-select{
			position: absolute !important;
			top:-20px;
			left:-20px;
			width: 0px;
			height: 0px;
			opacity: 0 !important;
			display: block !important;
		}
	}

	@include media-breakpoint-down(sm) {
		.chosen-select{
			border:none;
			border-radius: 0px;
			height: 45px;
			background-color: $bg-grey;
		}
	}	

	.chosen-container{
		width: 100% !important;

		.chosen-single{

			border-radius: 0px;
			border: none;
			background-color: $bg-grey;
			height: 48px;
			line-height: 48px;
			box-shadow: none !important;
			font-family: 'Gotham Light';
			text-decoration: none;
			font-size: 18px;

			b{
				&:before{
					content: "\f0d7";
					font-family: FontAwesome;
					position:relative;
					left:-5px;
				}
			}

		}

		.chosen-drop{
			border-radius: 0px;
			border: none;
			background-color: $bg-grey;
			box-shadow: none !important;			
		}

		a{
			text-decoration: none;
		}

	}
	.alerta-gracias{
		display: none;
	}
	.gracias-contacto{
		h2{
			margin-top: 50px;
			margin-bottom: 20px;
			color:$color-magenta;
			border-bottom: 1px solid #C4C4C4;
			margin-bottom: 40px !important;
		}
		.texto{
			@include fontSize(20px);
			font-family: 'Gotham Book';
			color: $negro;
		}
		.small{
			@include fontSize(16px);
			font-family: 'Gotham Book';
			color: $negro;
		}
		.btn-primary{
			color:$blanco;
			text-decoration:none;
			margin-top:40px;
			@include media-breakpoint-up(sm) {
				line-height: 2.2em;
			}
		}
	}

}

.contenedor-contacta {
	margin-top: 60px;
	@include media-breakpoint-down(md) {
		margin-top: 30px;
	}
	@include media-breakpoint-down(sm) {
		margin-top:0px;
	}
	.inner-contenedor-formulario{
		margin-top:30px;
		@media (max-height: 700px) {
			margin-top:20px;
		}
	}
	h1{
		@include fontSize(20px);
		font-family: 'Gotham Bold';
		color: $negro;
		padding-bottom:10px;
		border-bottom:2px solid #C4C4C4;
		margin-top:15px;
	}
	.texto{
		margin-top:30px;
		@include fontSize(16px);
		font-family: 'Gotham Book';
	}
	p,ul{
		color:$negro;
		margin-top:10px!important;
	}
	h2{
		color:$negro;
	}
	label {
		color:$negro;

	}
	.btn-primary{
		margin-bottom:15px;
		background: linear-gradient(262.04deg, #E5007D -1.64%, #FF7CC4 158.46%);
		border-radius:100px;
		color:$blanco;
		margin-left:auto;
		margin-right:auto;
		min-width: 150px;
		width:auto!important;
		display: inline-block!important;
		@include fontSize(16px);
		font-family: 'Gotham Medium';
		line-height: 2.28em;
		margin-top:40px;
		text-decoration:none;
		&.small{
			line-height: 1.7em;
			height: 40px;
			margin-top:0px;
		}
		@media (max-height: 700px) {
			margin-top:20px;
			height: 35px;
		}
		
	}
	.cajaCheck{
		label{
			@include fontSize(10px!important);
			font-family: 'Gotham Book'!important;
			a{
				text-decoration:underline;
				font-family: 'Gotham Book'!important;
			}
		}
	}
	.total_creditos{
		font-family: "Gotham Book";
		@include fontSize(24px);
		line-height: 1.2em;
		color:$negro;
		margin-top:0px;
		@include media-breakpoint-down(sm) {
			@include fontSize(15px);
		}
		@include media-breakpoint-down(xs) {
			@include fontSize(20px);
			padding-bottom:10px;
			padding-left: 15px;
			padding-right: 15px;
		}
		span{
			font-family: "Gotham Bold";
		}
	}
}


.gm-style .gm-style-iw-c{
	border-radius: 0;
	border: 2px solid black;
	padding-top: 25px;
	a{
		color: white;
		background-color: black;
		padding: 2px 15px 2px;
		float: right;
		margin-top: 10px;		
	}
}
.gm-style .gm-style-iw-t::after{
	background-color: white;
	border-left: 2px solid black;
	border-bottom: 2px solid black;
}


.cargador-planes{
	position:fixed;
	top:0;
	left:0;
	width: 100vw;
	height: 100vh;
	background-color:rgba(255,255,255,0.95);
	color:$negro;
	z-index: 20;
	&:after{
		content: " ";
		background-image: url(../images/spinner.svg);
		background-size: 22px;
		width: 22px;
		height: 22px;
		position: absolute;
		left: 50%;
		margin-left: -11px;
		top: 50%;
		margin-top: -11px;
		@include giroinfinito(1.5s);
	}
}
#iconos{
	.imgs-networks{
		@include media-breakpoint-down(sm) {
			padding-top:15px;
		}	
	}
}

#section-partner{
	.cabecera{
		height: auto;
		min-height: 330px;
		background-repeat: no-repeat;
		background-position: center;
		background-color: $negro;
		background-size: cover;
	}
	.titular{
		background-color: rgba($blanco,.8);
		color:$negro;
		@extend .fuente-title;
		@include fontSize(24px);
		padding: 10px 0 10px 30px;
	}
	h3{
		color:$negro;
		@extend .fuente-title;
		@include fontSize(20px);
	}
	.text-descripcion{
		a{
			color:$negro;
			text-decoration: underline;
		}
	}
	.info-inferior{
		background-color: $txt-grey;
		margin-top: 30px;
		.iconos-redes{
			list-style: none;
			margin: 20px 0;
		}
	}
	.carrusel-testimonials{
		background-color: $bg-grey;
		margin-top: 30px;
		padding: 30px 0;
		.texto{
			@extend .fuente-title;
			@include fontSize(22px);
			font-style: italic;
		}
		.nombre{
			font-family: 'Gotham Light';
			@include fontSize(18px);
			
		}
		.swiper-button-next{
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M27 22L5 44l-2.1-2.1L22.8 22 2.9 2.1 5 0l22 22z' fill='%239d9c9c'/%3E%3C/svg%3E");
			@include transform(scale(0.5));
		}
		.swiper-button-prev{
			background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 27 44'%3E%3Cpath d='M0 22L22 0l2.1 2.1L4.2 22l19.9 19.9L22 44 0 22z' fill='%239d9c9c'/%3E%3C/svg%3E");
			@include transform(scale(0.5));
		}
	}
}

.cont-search-address{
	.cont-address{
		.input-group{
			height: 45px;
			.input-group-prepend{
				position: relative;
				&:after{
					content:" ";
					border-right: 2px solid black;
					margin-top: 7px;
					position: absolute;
					right: 0;
					height: calc(100% - 14px);
				}
				.input-group-text{
					border: 2px solid black;
					border-right: none;
					border-radius: 0;
					background-color: $blanco;
				}
			}
			input{
				border: 2px solid black;
				border-left: none;
				padding-left: 15px;
				width: calc(100% - 46px);
				@include fontSize(16px);
				box-shadow: none!important;
			}
		}
	}
	.cont-slider{
		.label-title{
			@include fontSize(16px);
			color:$negro;
		}
		.label-slider{
			@include fontSize(16px);
			color:$rosa;
			font-family: 'Gotham Bold';;
			float: right;
		}
		#slider{
			background-color: #bdbdbd;
			height: 4px;
			transform: translateY(3px);
			.ui-slider-handle{
				border-radius: 50%;
				width: 18px;
				height: 18px;
				background-color: black;
				border: none;
				top: -8px;
			}
			.slider-start{
				width: 0;
				background-color: $azul;
				height: 4px;
				margin-top: -1px;
				border-radius: 3px;
			}
		}
		&.disabled{
			opacity: 0.35;
			pointer-events: none;
		}
	}
	.cont-btn{

	}
	.txt_resultados{
		background-color: #ebebeb;
		padding: 8px;
		span{
			display: inline-flex;
			padding-left: 15px;
			color:$negro;
		}
	}
}
#carga{
	position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,.9);
    top: 0;
    left: 0;
    z-index: 10;
	img{
		@include giroinfinito(1.5s);
	}
}
.no-cursor{
	cursor: not-allowed !important;
}

body.planes{
	&.interior{
		padding-top: 85px;
		#wrapper{
			@include media-breakpoint-down(sm) {
				height:auto;
				position:relative;
			}
		}
		#section-plan{
			@include media-breakpoint-down(sm) {
				position:relative;
			}
			.seleccion{
				@include media-breakpoint-down(sm) {
					position:relative!important;
					top:0px;
				}
				@media (max-height: 700px) {
					position: relative;
					top: 0px;
					margin-left: 0px;
				}
			}
			.grid-items{
				@include media-breakpoint-down(sm) {
					margin-top: 0px !important;
				}
				@media (max-height: 700px) {
					margin-top:15px!important;
				}
			}
		}
		&.scroll{
			#section-plan{
				@include media-breakpoint-down(sm) {
					position:relative;
				}
				.seleccion{
					@include media-breakpoint-down(sm) {
						position:relative!important;
						top:0px;
						left:15px;
					}
					@media (max-height: 700px) {
						position:relative;
						top:0px !important;
						margin-left: 0px;
						box-shadow:none;
					}
				}
				.grid-items{
					@include media-breakpoint-down(sm) {
						margin-top: 0px !important;
					}
				}
			}
		}
	}
}